import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from '../../util/reactIntl';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import { H3, Page, LayoutSingleColumn } from '../../components';
import css from "./TermsOfServicePage.module.css";

// This "content-only" component can be used in modals etc.
const TermsOfServiceContent = props => {
  return (
    <div className={css.container}>
      <H3 as="h2" className={css.title}>
        Allgemeine Geschäftsbedingungen der dealgo.ch
      </H3>
      <ol className={css.mainList}>
        <li>
          <strong>Anwendbarkeit</strong>
          <p>
            Diese Allgemeinen Geschäftsbedingungen von dealgo.ch regeln die Nutzung der von dealgo.ch betriebenen Online-Plattformen. Die Allgemeinen Geschäftsbedingungen finden insbesondere auch Anwendung auf sämtliche vertraglichen Vereinbarungen mit dealgo.ch. Personen, die eine vertragliche Vereinbarung mit dealgo.ch  abgeschlossen haben, werden nachfolgend als Kunden bezeichnet.
          </p>
        </li>
        <li>
          <strong>
            Inkrafttreten, Widerrufsrecht, Rücktritt durch dealgo.ch
          </strong>
          <p>
            dealgo.ch hat jederzeit das Recht, das Benutzerkonto eines Kunden ohne Angabe von Gründen zu deaktivieren oder eine abgeschlossene Vereinbarung ohne Angabe von Gründen und ohne Entschädigungsfolgen innerhalb von 14 Tagen seit Abschluss aufzulösen.
            Im Fall einer vertragswidrigen, widerrechtlichen oder sittenwidrigen Benutzung der Marktplätze und/oder der über die Marktplätze angebotenen Produkte oder Dienstleistungen durch den Kunden oder im Fall, dass der Kunde wegen mangelnder Qualität der an dealgo.ch  übermittelten Daten, wie beispielsweise Insertionsdaten, Anlass zu Reklamationen gegeben hat, kann dealgo.ch  ohne Entschädigungsfolgen sofort von der Vereinbarung zurücktreten und die Leistungserbringung ohne vorgängige Mitteilung an den Kunden und ohne Entschädigungsfolgen einstellen bzw. das Benutzerkonto des Kunden deaktivieren; in beiden Fällen verfällt ein bereits im Voraus für eine bestimmte Laufzeit bezahlter Preis, und dem Kunde steht kein Rückforderungsanspruch zu. Sämtliche Ansprüche von dealgo.ch  bleiben vorbehalten.
          </p>
        </li>
        <li>
          <strong>
            Nutzung der Marktplätze mit oder ohne Registration
          </strong>
          <p>
            Die Nutzung der Marktplätze kann mit oder ohne Registration erfolgen. Die Nutzung gewisser Dienstleistungen und Produkte erfordert jedoch eine Registration. Es können sich ausschliesslich Personen bei dealgo.ch  registrieren, welche mindestens {0} Jahre alt sind. dealgo.ch  behält sich das Recht vor, die Registrierung auf bestimmte Länder einzugrenzen.
            Für dealgo.ch  gilt als Kunde diejenige Person, welche die Benutzeridentifikation und Passwort verwendet, unabhängig davon, ob diese Person tatsächlich die Zugriffsberechtigung besitzt. Die Verantwortung für den sachgemässen Umgang mit Benutzeridentifikation und Passwort liegt bei der Person, die sich registriert hat.
          </p>
        </li>
        <li>
          <strong>
            Preise und Zahlungsbedingungen
          </strong>
          <p>
            Falls nicht ausdrücklich anders vereinbart, gilt für die Berechnung der Preise die jeweils gültige Preisliste. dealgo.ch  behält sich vor, die Preise jederzeit der Markt- und/oder Preisentwicklung anzupassen. Die Preise verstehen sich, falls nicht ausdrücklich anders vereinbart, netto in Schweizer Franken inklusive Mehrwertsteuer. Je nach Produkt bzw. Dienstleistung von dealgo.ch  ist eine Vorauszahlung erforderlich, welche über die von dealgo.ch  angebotenen Zahlungsmöglichkeiten abgewickelt werden kann, oder die Zahlungsfrist beträgt 10 Tage ab dem Zeitpunkt der Rechnungsstellung, vorbehalten bleiben abweichende Zahlungsfristen gemäss separater Vereinbarung resp. abweichender Angabe auf der Rechnung.
          </p>
          <p>
            Bezahlt der Kunde nicht innerhalb der Zahlungsfrist, so fällt der Kunde automatisch in Verzug. Zudem kann dealgo.ch  die Erfüllung der Leistung verweigern und die Vereinbarung frist- und entschädigungslos ohne Mahnung auflösen. Im Falle von nicht fristgerecht bezahlten Zahlungen während laufender Vertragsdauer hat dealgo.ch  zudem das Recht, den Zugang des Kunden zu seinem Benutzerkonto zu sperren. Mit vollständiger Bezahlung aller offenen Rechnungen wird die Sperrung wieder aufgehoben. Der Kunde hat in diesem Fall kein Anrecht auf eine Verlängerung der vereinbarten Vertragsdauer um die Dauer der Sperrung. Nach Ablauf der Zahlungsfrist ist der gesetzliche Verzugszins geschuldet. Zusätzlich sind Mahnkosten von CHF 50.00 pro Mahnung zu bezahlen. Im Weiteren trägt der Kunde den dealgo.ch  durch den Zahlungsverzug und/oder die Auflösung der Vereinbarung entstandenen Schaden.
          </p>
        </li>
        <li>
          <strong>
            Verbot der Weiterverwendung von Informationen
          </strong>
          <p>
            Die kommerzielle Nutzung von Informationen und Inhalten ist Kunden vorbehalten. Davon abgesehen, erwerben die Kunden keinerlei Rechte an Inhalten oder Informationen, welche auf den Marktplätzen zugänglich gemacht werden. Zuwiderhandlungen werden zivil- und strafrechtlich geahndet. Die Kunden verpflichten sich ausdrücklich, die auf den Marktplätzen zugänglich gemachten Informationen und Inhalte in keiner Weise oder Form ausserhalb der Marktplätze zu verwenden. Insbesondere unterlassen es die Kunden, die auf den Markplätzen zugänglichen Daten zu kopieren, zu veröffentlichen oder sonst wie (z.B. im Internet) in irgendeiner Form wiederzugeben, oder mit anderen Daten zu verknüpfen.
          </p>
        </li>
        <li>
          <strong>
            Immaterialgüterrechte
          </strong>
          <p>
            Sämtliche Rechte an Informationen, Elementen und Inhalten der Marktplätze, insbesondere darin enthaltene Urheberrechte, Marken und Bezeichnungen sind Eigentum von dealgo.ch  oder Dritten, die dealgo.ch  entsprechende Nutzungsrechte eingeräumt haben. Soweit die vertragsgemässe Nutzung der Marktplätze die Einräumung von Nutzungsrechten an den Kunden mit Bezug auf Urheberrechte, Marken und/oder Know-how von dealgo.ch  voraussetzt, werden diese dem Kunden nicht-exklusiv, unübertragbar und im benötigten Umfang für die Dauer der entsprechenden Vereinbarung durch dealgo.ch  erteilt. Die dem Kunden eingeräumten Nutzungsrechten sind persönlich und nicht übertragbar. Vereinbart der Kunde mit dealgo.ch  die Nutzung eines Dienstes eines Partners (vgl. Ziffer 14), so gilt der vorliegende Abschnitt analog auch für solche Dienste.
            Inhalte, Daten, Texte, Videos, Musikstücke, Grafiken und sonstige Inhalte, die der Kunde zur Publikation auf einem Marktplatz an dealgo.ch  übermittelt (nachfolgend insgesamt "Insertionsdaten"), dürfen keinerlei Rechte verletzen, insbesondere keine Rechte Dritter wie z.B. Persönlichkeitsrechte und Immaterialgüterrechte. Der Kunde darf nur Insertionsdaten an dealgo.ch  übermitteln, welche er selber erstellt hat oder deren Verwendung der Rechteinhaber zugestimmt hat. Auf Fotos, die in den Inseraten verwendet werden, dürfen keine Adressen (E-Mail Adresse oder Postadresse) noch Telefonnummern oder Autokennzeichen sichtbar sein. Fotos mit Personen, deren Gesicht auf den Fotos klar erkennbar ist, dürfen nur mit Zustimmung der entsprechenden Person verwendet werden. Sämtliche im Auftrag des Kunden durch dealgo.ch  erstellten Objektunterlagen, Fotos und Templates sind Eigentum des Kunden, sobald der Kunde die entsprechende Rechnung vollständig an dealgo.ch  bezahlt hat.
          </p>
        </li>
        <li>
          <strong>
            Bewirtschaftung und Weiterentwicklung der Marktplätze
          </strong>
          <p>
            Der Betrieb und die Bewirtschaftung der Marktplätze erfolgen durch dealgo.ch. Diese ist berechtigt, zur Erfüllung ihrer Leistungspflichten Dritte beizuziehen. dealgo.ch  ist bestrebt, den Betrieb der Marktplätze von dealgo.ch  möglichst störungsfrei anzubieten und Unterbrechungen für die Behebung von Störungen, Wartungsarbeiten, Einführungen neuer Technologien und dergleichen kurz zu halten, kann diese aber nicht vollständig ausschliessen. Die technische Weiterentwicklung liegt allein im Ermessen von dealgo.ch. Die Dienste von Partnern (vgl. Ziffer 14) werden durch die entsprechenden Partner betrieben und bewirtschaftet. Die technische Weiterentwicklung liegt in deren Ermessen.
          </p>
        </li>
        <li>
          <strong>
            Publikation von Inseraten
          </strong>
          <p>
            Durch die Übermittlung von Insertionsdaten an dealgo.ch  oder einen Partner von dealgo.ch (vgl. Ziffer 14) ermächtigt der Kunde dealgo.ch, das entsprechende Inserat auf den Marktplätzen sowie auf weiteren Online- und Offline-Kanälen von dealgo.ch (z.B. im Rahmen von Messen) sowie auf Online- und Offline-Kanälen von Partnern von dealgo.ch  zu integrieren. Ein Anspruch auf Publikation der Inserate innerhalb dieser Dienste existiert jedoch nicht.
          </p>
          <p>
            dealgo.ch kann die Publikation der Inserate jederzeit, aus welchem Grund auch immer, ohne Entschädigung unterbrechen, verändern oder gänzlich unterbinden. Ebenso kann dealgo.ch  die Insertionsdaten für weitere eigene Zwecke und solche von Partnern, wie beispielsweise für die Erstellung von Statistiken, nutzen oder sonst wie veröffentlichen und zu diesem Zweck speichern und bearbeiten. Der Kunde stimmt der direkten oder indirekten Übergabe der für die Nutzung der Dienste von Partnern (vgl. Ziffer 14) notwendigen Insertionsdaten sowie der Speicherung solcher Daten in die Datenbank(en) von Partnern und der Nutzung dieser Insertionsdaten durch die Partner ausdrücklich zu. dealgo.ch  kann die Vertraulichkeit, Unverfälschbarkeit und Authentizität der publizierten Insertionsdaten des Kunden durch die Partner nicht gewährleisten.
          </p>
        </li>
        <li>
          <strong>
            Datenschutz
          </strong>
          <p>
            Für die Bearbeitung von Daten (insb. personenbezogene Daten) wird auf die Datenschutzbestimmungen verwiesen.
          </p>
        </li>
        <li>
          <strong>
            Rechte und Pflichten des Kunden
          </strong>
          <p>
            Der Kunde verpflichtet sich, die Marktplätze und die darin eingebundenen Dienste von Partnern (vgl. Ziffer 14) gesetzes- und vertragsgemäss zu nutzen. Der Kunde sichert insbesondere zu, dass:
          </p>
          <ul style={{ listStyleType: "disc", paddingLeft: 20 }}>
            <li>
              er sämtliche an dealgo.ch  oder Partnern übermittelte Insertionsdaten in der von dealgo.ch  vorgegebenen Form und Qualität liefert, gemäss den Insertionsregeln der entsprechenden Marktplätze;
            </li>
            <li>
              er an sämtlichen von ihm direkt oder indirekt über Dritte dealgo.ch  oder Partnern zur Verfügung gestellten Insertionsdaten berechtigt ist und im Rahmen der vertraglichen Vereinbarungen mit dealgo.ch  verwenden darf;
            </li>
            <li>
              die an dealgo.ch  oder Partnern übermittelten Insertionsdaten vollständig und wahr sind, nicht irreführen oder unlauter sind sowie von ihm ständig aktuell gehalten werden;
            </li>
            <li>
              die von ihm angebotenen Produkte oder Dienstleistungen korrekt und wahrheitsgetreu beschrieben sind, die beschriebenen Eigenschaften aufweisen und nicht gegen anwendbare Gesetze oder Rechte Dritter (insb. Immaterialgüterrecht und Persönlichkeitsrecht) verstossen;
            </li>
            <li>
              er diese Allgemeinen Geschäftsbedingungen akzeptiert und jederzeit einhält.
            </li>
          </ul>
          <p>
            dealgo.ch ist jederzeit befugt aber nicht verpflichtet, die vom Kunden übermittelten Insertionsdaten aus rechtlichen oder technischen Gründen zu verändern oder gänzlich nicht zu verarbeiten.
            Der Kunde überträgt dealgo.ch  das ausschliessliche Recht, die zur Verfügung gestellten Insertionsdaten zu nutzen. Die Insertionsdaten dürfen von Dritten nicht verwendet, übernommen oder kopiert werden, ausgenommen sind Partner von dealgo.ch. Der Kunde hat keinen Anspruch darauf, dass Dritte die von ihm zur Verfügung gestellten Insertionsdaten zum Zwecke der Veröffentlichung auf deren Plattformen beziehen können. Selbst wenn der Kunde dem Dritten eine Zustimmung zu einem solchen Datenbezug erteilt, ist dealgo.ch  nicht verpflichtet, den Datenbezug zu gewähren.
          </p>
          <p>
            Wird dealgo.ch  im Zusammenhang mit der Nutzung der Marktplätze, einer Vereinbarung mit dem Kunden, mit von Kunden zur Verfügung gestellten Insertionsdaten oder angebotenen Produkten bzw. Dienstleistungen von einem Partner oder Dritten gerichtlich belangt, so ist der Kunde verpflichtet, dealgo.ch  auf erste Aufforderung hin vollständig klag- und schadlos zu halten, inklusive Übernahme der gerichtlichen und aussergerichtlichen Kosten von dealgo.ch. Auf Verlangen von dealgo.ch  hat der Kunde auch sämtliche Dokumente herauszugeben, die dealgo.ch  nützlich erscheinen, um die Klage abzuwenden und ist verpflichtet, dem Prozess beizutreten.
          </p>
        </li>
        <li>
          <strong>
            Risiko
          </strong>
          <p>
            Der Kunde trägt sämtliche Risiken, die infolge von Manipulationen an seinem EDV-System, infolge von Funktionsstörungen seines EDV-Systems und/oder infolge missbräuchlicher Verwendung von Zugriffsberechtigungen auftreten können.
          </p>
        </li>
        <li>
          <strong>
            Haftung und Gewährleistung
          </strong>
          <p>
            Die Marktplätze enthalten Informationen und Inhalte von dealgo.ch  sowie von Inserenten und Partnern von dealgo.ch. dealgo.ch  bemüht sich, die Korrektheit dieser Informationen und Inhalte sicherzustellen, gibt aber diesbezüglich ausdrücklich keinerlei Zusicherungen oder Gewährleistungen ab. Dies betrifft insbesondere die Wahrheit, Richtigkeit, Vollständigkeit und Aktualität der Informationen und Inhalte. dealgo.ch  lehnt auch jede Haftung für Schäden ab, welche aus dem Abruf, Zugriff, der Nutzung oder der Verwendung dieser Informationen und Inhalte entstehen können. Der Abruf und die Verwendung solcher Informationen und Inhalte erfolgen ausschliesslich auf Risiko des Kunden.
          </p>
          <p>
            Der Kunde ist vollumfänglich für die von ihm über die Marktplätze angebotenen Produkte und Dienstleistungen sowie für die von ihm an dealgo.ch  übermittelten Inhalte (insb. auch für die Insertionsdaten, Inserate und Printaufträge) verantwortlich. dealgo.ch  lehnt jede Haftung für die Inhalte des Kunden ab. Insbesondere übernimmt dealgo.ch  keinerlei Gewährleistungen und Garantien für die vom Kunden über die Marktplätze angebotenen Produkte und Dienstleistungen sowie für die sich möglicherweise daraus ergebenden Vertragsabschlüsse, wie beispielsweise Kaufverträge unter Kunden oder mit Nutzern und Dritten.
          </p>
          <p>
            dealgo.ch prüft den Inhalt von mit den Marktplätzen verlinkten Webseiten nicht und ist für den Inhalt solcher verlinkter Webseiten nicht verantwortlich.
          </p>
          <p>
            dealgo.ch haftet nur bei Absicht oder grober Fahrlässigkeit. In keinem Fall haftet dealgo.ch  für Folgeschäden und entgangenen Gewinn. Kann dealgo.ch  trotz aller Sorgfalt aufgrund von höherer Gewalt wie beispielsweise Naturereignissen, kriegerischen Auseinandersetzungen, Streik, unvorhergesehenen behördlichen Restriktionen sowie technischen Störungen, welche dem Verantwortungsbereich Dritter zuzuordnen sind, ihren vertraglichen Verpflichtungen nicht nachkommen, besteht für die Dauer des Ereignisses kein Anspruch des Kunden auf Vertragserfüllung.
          </p>
          <p>
            Dem Kunden steht für Arbeiten, die sich nach Werkvertragsrecht beurteilen, ausschliesslich das Recht auf Nachbesserung durch dealgo.ch  zu. Wandelung und Minderung sind ausgeschlossen. Soweit gesetzlich zulässig, schliesst dealgo.ch  jede Haftung für Mangelfolgeschäden aus.
            dealgo.ch haftet nicht für den Missbrauch des Internets und damit verbundene Schädigungen des Kunden durch Dritte, für Sicherheitsmängel und Störungen der Fernmeldenetze von Dritten und des Internets sowie für Betriebsunterbrüche und Störungen der Marktplätze und von Angeboten Dritter.
          </p>
        </li>
        <li>
          <strong>
            Rechtsstellung von dealgo.ch
          </strong>
          <p>
            dealgo.ch ist nicht Vertragspartei von Verträgen zwischen Kunden, Nutzern oder sonstigen Dritten betreffend Produkte und Dienstleistungen, die über die Marktplätze von Kunden angeboten werden. dealgo.ch  ist in keiner Weise für den Vertragsschluss und damit allenfalls zusammenhängende Risiken verantwortlich.
          </p>
          <p>
            Die auf den Marktplätzen wiedergegebenen Informationen und Inhalte begründen weder eine Aufforderung zur Offertstellung noch ein Angebot oder eine Empfehlung zur Miete und/oder zum Erwerb durch dealgo.ch.
          </p>
          <p>
            dealgo.ch ist berechtigt aber nicht verpflichtet, das Verhalten von Nutzern und Kunden sowie deren Inhalte auf den Marktplätzen zu kontrollieren und auf ihre Zulässigkeit zu überprüfen.
          </p>
        </li>
        <li>
          <strong>
            Nutzung von Diensten von Partnern von dealgo.ch
          </strong>
          <p>
            Der Kunde akzeptiert bei jeder Nutzung von in Markplätzen integrierten Diensten von Partnern von dealgo.ch  die entsprechenden Nutzungsbestimmungen dieser Dienste.
          </p>
        </li>
        <li>
          <strong>
            Verrechnungsverbot
          </strong>
          <p>
            Der Kunde ist nicht berechtigt, seine Forderungen mit Forderungen von dealgo.ch  zu verrechnen.
          </p>
        </li>
        <li>
          <strong>
            Abtretungsverbot
          </strong>
          <p>
            Der Kunde darf das Vertragsverhältnis mit dealgo.ch  nicht ohne schriftliche Einwilligung von dealgo.ch  auf einen Rechtsnachfolger übertragen, ebenso wenig dürfen Forderungen aus dem Vertragsverhältnis mit dealgo.ch  ohne schriftliche Einwilligung von dealgo.ch  an Dritte abgetreten werden.
          </p>
        </li>
        <li>
          <strong>
            Bestätigung und Änderung dieser Allgemeinen Geschäftsbedingungen
          </strong>
          <p>
            Der Kunde bestätigt diese Allgemeinen Geschäftsbedingungen jedes Mal, wenn er ein Inserat bezahlt und auf einer der Marktplattformen von dealgo.ch  aufschaltet. Dadurch steht ihm immer die aktuellste Version der AGB zur Verfügung. dealgo.ch behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Die jeweils aktuelle Fassung wird auf den Marktplätzen an geeigneter Stelle veröffentlicht.
          </p>
        </li>
        <li>
          <strong>
            Allgemeine Bestimmungen
          </strong>
          <p>
            Allgemeine Bestimmungen Sollte eine Bestimmung einer Vereinbarung ungültig oder undurchführbar sein, so fällt diese nur im Ausmass ihrer Ungültigkeit oder Undurchführbarkeit dahin und ist im Übrigen durch eine der unwirksamen oder undurchführbaren Regelung wirtschaftlich möglichst nahekommende Ersatzbestimmung zu ersetzen. Allfällige Lücken der entsprechenden Vereinbarung sind durch Regelungen auszufüllen, welche dem am nächsten kommen, was die Parteien nach dessen Sinn und Zweck vereinbart hätten, wenn sie an den betreffenden Punkt beim Abschluss der entsprechenden Vereinbarung gedacht hätten.
          </p>
        </li>
        <li>
          <strong>
            Anwendbares Recht und Gerichtsstand
          </strong>
          <p>
            Diese Allgemeinen Geschäftsbedingungen unterstehen ausschliesslich schweizerischem Recht, unter Ausschluss des internationalen Kollisionsrechts (IPRG). Ausschliesslicher Erfüllungsort und Gerichtsstand ist am Sitz von dealgo.ch dealgo.ch  ist auch berechtigt, den Kunden an seinem Sitz/Wohnsitz zu belangen.
          </p>
        </li>
      </ol>
      <p>
        Stand: 1.10.2023 - dealgo.ch
      </p>
    </div>
  );
};

// Presentational component for TermsOfServicePage
const TermsOfServicePageComponent = props => {

  const intl = useIntl();
  const title = intl.formatMessage({ id: 'TermsOfServicePage.title' });

  return (
    <Page className={css.root} title={title}>
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <TermsOfServiceContent />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

TermsOfServicePageComponent.propTypes = {};

const mapStateToProps = state => {
  return {};
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TermsOfServicePage = compose(connect(mapStateToProps))(TermsOfServicePageComponent);

export { TermsOfServicePageComponent, TermsOfServiceContent };
export default TermsOfServicePage;
